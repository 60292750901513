import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { bitters } from "../constants/constants";
export default class Bitters extends React.Component {
  render() {
    const {
      data: {
        allRecipesJson: { edges }
      }
    } = this.props;
    const drinkRecipes = edges;
    const getMatchRecipes = (drinkRecipes, bitter) => {
      let result = [];

      const matchingItems = Object.keys(drinkRecipes).filter(
        recipe => drinkRecipes[recipe].node.bitter === bitter
      );

      if (matchingItems) {
        result = matchingItems.map(recipe2 => drinkRecipes[recipe2].node.title);
      }

      return result;
    };
    return (
      <Layout>
        <div className="container">
          <div className="section">
            <h1 className="title is-1">Bitters</h1>
          </div>
          <div className="box">
            {/* <h2 className="title is-4">
              <h2 className="subtitle is-2">
                What kind of bitters do you sell?
              </h2>
            </h2> */}
            <article className="message is-warning">
              <div className="message-header">
                <p>What kind of bitters do you sell?</p>
              </div>
              <div className="message-body">
                We have a wide selection to fit your imbibing pleasure. If
                you're looking for a classic, we recommend the Bitter Hedgehog
                Aromatic Bitters or Citrus Bitters. Branch out a little bit to
                the Allspice Bitters or Seattle Drizzle -- both add a perfect
                note to an Old Fashioned. Our Habanero Bitters is unique and
                perfect for tequila drinks, and the Ginger Bitters adds just a
                hint of ginger flavor that will give your cocktails a deeper
                flavor.
              </div>
            </article>
            {/* <p>

            </p> */}
            <br />
            {/* <h2 className="subtitle is-2">Our bitters include:</h2> */}
            <table className="table is-striped">
              <thead>
                <tr>
                  <th>Bitter</th>
                  <th>Description</th>
                  {/* <th>Recipes</th> */}
                </tr>
              </thead>
              <tbody>
                {/* start mapping */}
                {Object.keys(bitters).map((bitter, index) => (
                  <tr>
                    <th>{bitter}</th>
                    <td>
                      {bitters[bitter].description.length
                        ? [bitters[bitter].description, <hr />]
                        : null}
                      {getMatchRecipes(drinkRecipes, bitter).length ? (
                        [
                          getMatchRecipes(drinkRecipes, bitter).map(
                            (recipe, index) => (
                              <span
                                className="tag is-medium"
                                style={{
                                  marginRight: "6px",
                                  background: index % 2 ? "#EA8A52" : "#C0B71A",
                                  textColor: "black"
                                }}
                              >
                                {recipe}
                              </span>
                            )
                          )
                        ]
                      ) : (
                          <span className="tag is-light is-medium">
                            No recipes yet
                          </span>
                        )}
                    </td>

                    {/* <div className="column is-offset-1 is-4 has-text-centered">
                  <p>
                    {`Recipes with ${bitter}: `}
                    {getMatchRecipes(drinkRecipes, bitter)}
                  </p>
                </div> */}
                  </tr>
                ))}
                {/* end mapping */}
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
    );
  }
}

export const bittersQuery = graphql`
  query BittersQuery {
    allRecipesJson {
      edges {
        node {
          spirit
          bitter
          direction
          title
        }
      }
    }
  }
`;
