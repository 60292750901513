export const bitters = {
  Absinthe: {
    description:
      "On vacation in New Orleans, I had to try absinthe, even though I don't love the taste of black licorice (I told the waitress that I did, though, because I knew that was what absinthe tasted like). And it was delicious and complex. You can add that flavor to your cocktails without tracking down the 'green fairy' by putting in a few drops of Absinthe Bitters."
  },
  Allspice: {
    description:
      "In a taste test, this was our favorite for an Old Fashioned. The slight spiciness brings a unique flavor without overpowering a drink."
  },
  Aromatic: {
    description:
      " This is one of the most classic bitters flavors, and is essential for any home bar. Aromatic bitters are found in both sweet and savory drinks made of a variety of spirits. It's hard to say what aromatic bitters taste like -- spice, certainly -- but give it a try and you'll be searching for recipes to use them in."
  },
  Citrus: {
    description:
      "A classic bitters flavor, many of the commercial orange bitters are made in glycerin instead of alcohol. You can see everything that's going into our Citrus Bitters, and those real ingredients will add the perfect note to your Gin & Tonic, Manhattan, Martini, or more."
  },
  Cardamom: {
    description:
      "Start re-imagining your afternoon iced coffee! A dash of cardamom bitters will give every drink a special twist leaving the recipient wondering what that secret ingredient is that makes the drink taste so nice."
  },
  Chocolate: { description: "" },
  Ginger: {
    description:
      "Ginger is a great flavor to add to a cocktail, but ginger liquor makes a drink strong, ginger simple syrup makes it sweet, and ginger beer is not for everyone (we don't always love the fizz that comes with it, and it's hard to add just a splash to a drink). The solution? Ginger Bitters. This is delicious and makes each drink unique."
  },
  "Green Tabasco": {
    description:
      "Weird, right? But if you like Bloody Mary's, there are a lot of cocktails that you might like with Green Tabasco Bitters. The more important role of this bitters, however, is for seafood. Add a few dashes to your melted butter to dip your crab, put a few drops on each grilled oyster, or add some to your steamed clams or mussels."
  },
  Habanero: {
    description:
      "You'll be glad we did the hard work of handling the habaneros for you, because these peppers are hot! But when they're made into bitters it adds flavor without too much spice (definitely some spice!) that allows you to bring down the sweetness in a drink."
  },
  Lemongrass: {
    description:
      "Without being too spicy or too citrusy, Lemongrass Bitters gives a nuanced flavor to your cocktails."
  },
  Mint: { description: "" },
  "Mint Julep": {
    description:
      " It's true, we've tried side-by-side tests, and a mint julep with Mint Julep Bitters is way tastier than a mint julep without it. Plus, this is really refreshing in soda water as an afternoon treat."
  },
  "Root Beer": {
    description:
      "Adding a bit of Root Beer Bitters to sparkling water makes a drink reminiscent of old fashioned root beer, and it provides a depth of flavor to any brown liquor drink. Sadly, we can't sell this one because there's an ingredient that maybe causes cancer, but we thought you might like to know it's there."
  },
  Rosemary: {
    description:
      "Rosemary works in all seasons, and gives a bright flavor to any drink, but especially cocktails with gin or grapefruit."
  },
  Saffron: {
    description:
      " This just calls for champagne to pair with the Saffron Bitters, and it adds the loveliest red color to your drinks."
  },
  "Scorched Earth": {
    description:
      "If Seattle Drizzle is cozy, this is aggressive, but that doesn't mean it's not delicious. It's several important tasters' favorite bitters. This is the prettiest bitters as you make it -- bright red chiles, cheerful green zest, blackened ancho chiles, and more."
  },
  "Seattle Drizzle": {
    description:
      " If you are drinking a Negroni on a rainy autumn day, you should be adding Seattle Drizzle Bitters to it. Hints of coffee and spices makes any drink just a little cozier when you add this."
  }
};

export const spirits = {
  Bourbon: {},
  Rye: {},
  "Irish Whiskey": {},
  Vodka: {},
  Cognac: {},
  Tequila: {},
  Gin: {},
  Rum: {},
  Brandy: {}
};
